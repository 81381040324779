import initSearch from "@rei/search-input-suggestions";

// TODO SE-2870 - import from SIS package
const AUTOSUGGEST_SELECTOR = '[data-js=suggestion-box]';
const GNAV_DATA_SELECTOR = '#gnav-data';

/**
 * Initializes search - handles race conditions
 * In prod, the mounting point will almost always be available by the time
 * this code runs. However, during local dev, the page may still be loading
 * the page / global navigation header, so this allows for the fallback scenario
 * where page load has not yet occurred.
 *
 * @param {String} el: The selector for the mounting point for the
 *   search autosuggest component
 * @param {Boolean} toggle: whether or not to init search-input-suggestions
 *   by default.
 * @returns void
 */
const initSearchWithConfig = (
  gnavSelector = GNAV_DATA_SELECTOR,
  autoSuggestMountEl = AUTOSUGGEST_SELECTOR,
) => {

  const hasInit = false;

  /**
   * Checks if the mounting element is available in the DOM.
   * @returns {boolean}
   */
  const isReady = () => {
    const mountEl = document?.querySelector(autoSuggestMountEl);
    return mountEl instanceof HTMLElement;
  };


  // Runs the initialization logic and ensures it executes only once.
  const initialize = () => {
    if (hasInit || !isReady(autoSuggestMountEl)) return;
    initSearch(gnavSelector);
  };

  // initialize now if mounting el is found, else wait for DOMContentLoaded
  if (isReady(autoSuggestMountEl)) {
    initSearch(gnavSelector);
  } else {
    window.addEventListener('DOMContentLoaded', initialize, { once: true });
  }
}

export default initSearchWithConfig;
